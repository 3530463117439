<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-6">Directory</div>
            <SearchPeople
                v-model="foundEmployee"
                label="Search people"
                :show-loader="false"
                employees-only
                rounded
                outlined
            />
            <v-expand-transition>
                <v-card
                    v-if="employee.id"
                    class="mt-7"
                    rounded
                    outlined
                >
                    <v-card-title class="text-h5">{{ employeeName }}</v-card-title>
                    <v-card-subtitle>{{ employee.title }}</v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col v-if="employee.division" cols="12" sm="4">
                                <div class="overline">Division</div>
                                {{ employee.division.long_name }}
                            </v-col>
                            <v-col v-if="employee.email" cols="12" sm="4">
                                <div class="overline">Email</div>
                                <a :href="`mailto:${employee.email}`" v-text="employee.email" />
                            </v-col>
                        </v-row>
                        <div class="text-center mt-6">
                            <v-btn
                                :to="{name: 'directory/employee', params: {uni: employee.cu_uni}}"
                                small
                            >View More Details</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-expand-transition>
            <div class="text-h5 font-weight-light mt-5 mb-2">Divisions</div>
            <v-expansion-panels focusable>
                <v-expansion-panel
                    v-for="(div, i) in allDivisions"
                    :key="i"
                    :id="`div${i}`"
                    @click="loadDivisionEmployees(div, i)"
                >
                    <v-expansion-panel-header>{{ divNameOnly(div.name) }}</v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-10 pl-10 pr-10 pb-5" v-if="loadingDivEmployees === i">
                        <v-progress-linear
                            v-show="loadingDivEmployees"
                            indeterminate
                            rounded
                            height="3"
                        />
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else-if="divEmployees.length">
                        <v-data-table
                            :headers="divEmployeeHeaders"
                            :items="divEmployees"
                            dense
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:item.email="{ item }">
                                <a v-if="item.email" :href="`mailto:${item.email}`" v-text="item.email" />
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                     <v-expansion-panel-content v-else>
                         <div class="text-center pt-5 grey--text">
                             <v-icon color="grey" left>mdi-information</v-icon>No employees to show
                         </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
    </v-main>
</template>
<script>
import axios from 'axios';
import { ref, watch, onMounted } from '@vue/composition-api';

import useEmployees from '@/composables/population/useEmployees';

import stringHelper from '@/composables/common/string';

import SearchPeople from '@/components/population/SearchPeople';

export default {
    components: {
        SearchPeople
    },
    setup(_, { root }) {
        const foundEmployee = ref(null);
        const allDivisions = ref([]);
        const divEmployees = ref([]);
        const loadingDivEmployees = ref(false);

        const divEmployeeHeaders = [{
            text: 'First Name',
            value: 'first_name',
            width: 150
        }, {
            text: 'Last Name',
            value: 'last_name',
            width: 150
        }, {
            text: 'Title',
            value: 'title'
        }, {
            text: 'Email',
            value: 'email',
            width: 100
        }];

        const {
            employee,
            employeeName,
            loadEmployee
        } = useEmployees({
            initial: foundEmployee.value
        });

        const { divNameOnly } = stringHelper();


        const loadDivisionEmployees = async (div, i) => {
            loadingDivEmployees.value = i;
            divEmployees.value = [];
            const response = await axios.get(`/population/load_division_employees/${div.id}/`);
            divEmployees.value = response.data;
            loadingDivEmployees.value = false;
            root.$vuetify.goTo(`#div${i}`, {offset: 40});
        };


        watch(foundEmployee, () => {
            loadEmployee(foundEmployee.value.employee_id);
        });

        onMounted(async () => {
            const response = await axios.get('/population/divisions/');
            allDivisions.value = response.data;
        });

        return {
            foundEmployee,
            employee,
            employeeName,
            allDivisions,
            loadDivisionEmployees,
            divEmployees,
            loadingDivEmployees,
            divEmployeeHeaders,
            divNameOnly
        };
    }
};
</script>