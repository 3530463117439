import axios from 'axios';
import { ref, computed } from '@vue/composition-api';

export default function useEmployees(payload={}) {

    const loadingEmployee = ref(false);
    const employee = ref({});

    const employeeName = computed(() => {
        let name = [employee.value.first_name, employee.value.last_name];
        if (employee.value.middle_name) name.splice(1, 0, employee.value.middle_name)
        return name.join(' ');
    });

    const loadEmployee = async (id=null) => {
        loadingEmployee.value = true;
        if (payload.initial) employee.value = payload.initial;
        id = id ? id : payload.id;
        const response = await axios.get(`/population/load_employee/${id}/`);
        employee.value = response.data;
        loadingEmployee.value = false;
    }

    return {
        employee,
        employeeName,
        loadingEmployee,
        loadEmployee
    }
}